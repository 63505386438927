
/// Class responsible for sending Gamepad events though a socket to the server.
export class GameSocket {

    constructor(id) {
	
	var url;
	if (window.location.protocol !== 'https:') {
	    url = `ws://${window.location.hostname}:8080/gamepad`;
	} else {
	    url = `wss://${window.location.host}/gamepad`;
	}
	this.socket = new WebSocket(url);
	console.log(`websocket url: ${url}`)
	var socket = this.socket
	this.socket.onopen = function(e) {
	    socket.send(id);
	    console.log(`sent key: ${id}`)
	    alert('Connected to server: ' + window.location.hostname);
	};

	this.socket.onerror = function(e) {
	    alert("Error: can't establish connection to "+window.location.hostname);
	}

	this.socket.onclose = function(e) {
	    alert("Disconnected from server");
	}

	
    }

    setMessageListener(listener){
	this.socket.onmessage = listener
    }

    requestGameInfo(){
	const msg = JSON.stringify({
	    messageType: "gameInfo",
	    timestamp: Date.now()	    
	});
	this.sendMessage(msg)
    }

    requestGameList(){
	const msg = JSON.stringify({
	    messageType: "listGames",
	    timestamp: Date.now()	    
	});
	this.sendMessage(msg)
    }

    buttonEvent(button, pressed) {
	const msg = JSON.stringify({
	    messageType: "buttonEvent",
	    data: {
		button: button,
		pressed: pressed	
	    },
	    timestamp: Date.now() 
	});
	this.sendMessage(msg)
	
    }

    joystickEvent(data) {
	const msg = JSON.stringify({
	    messageType: "joystickEvent",
	    data: {
		angle: data.angle.radian,
		distance: data.distance
	    },
	    timestamp: Date.now()
	});

	this.sendMessage(msg)
    }

    async sendMessage(msg){
	const readyState = this.socket.readyState;
	console.log(`State: ${readyState}, event: ${msg}`);

	while (this.socket.readyState === 0){
	    await sleep(100)
	}
	
	if (this.socket.readyState === 1) {
	    this.socket.send(msg);
	} else {
	    console.error("Connection not ready")
	}
    }
}


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
