import React from "react";
import "./Gamepad.css";
import ReactNipple from "@julienusson/react-nipple";
import "@julienusson/react-nipple/lib/styles.css";
import logo from "./../assets/logo_white.svg";

var gameSocket

/// Button on gamepad. Listens for clicks and sends data to socket.
class Button extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    active: false,
	};
    }

    clickStart() {
	this.setState({ active: true });
	gameSocket.buttonEvent(this.props.value, true);
    }

  clickEnd() {
      this.setState({ active: false });
      gameSocket.buttonEvent(this.props.value, false);
  }

  render() {
    return (
      <button
        className={this.state.active ? "button-active" : "button"}
        onTouchStart={() => this.clickStart()}
        onTouchEnd={() => this.clickEnd()}
        onMouseDown={() => this.clickStart()}
        onMouseUp={() => this.clickEnd()}
      >
        {this.props.value}
      </button>
    );
  }
}

/// Class containing buttons and a joystick.
class Gamepad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

      gameSocket = this.props.gameSocket;
  }

    // Change gamepad size on screen resize
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  renderButton(i) {
    return <Button value={i} />;
  }
  render() {
    return (
	<div className="Gamepad">
	    {/* Joystick class */}
            <ReactNipple
		className="Joystick"
		options={{
		    mode: "static",
		    position: { top: "50%", left: "50%" },
		    size: this.state.width / 5,
		}}

            // all events supported by nipplejs are available as callbacks
		onMove={(evt, data) => gameSocket.joystickEvent(data)}
		onMouseUp={(evt) => gameSocket.joystickEvent({angle:0.0,magnitude:0.0})}
		onTouchEnd={(evt) => gameSocket.joystickEvent({angle:0.0,magnitude:0.0})}
            />
            <div className="LogoContainer">
		<img src={logo} alt="Videowindow logo" className="logo" />
		<p className="MiddleText">Videowindow Gamepad</p>
            </div>
            <div className="Buttons">
		{this.renderButton("X")}
		<div className="ABButtons">
		    {this.renderButton("Y")}
		    {this.renderButton("A")}
		</div>
		{this.renderButton("B")}
            </div>
	</div>
    );
  }
}

export { Gamepad };
