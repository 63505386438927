import React from "react";
import "./App.css";
import { Gamepad } from "./gamepad/Gamepad.js";
import { Connect } from "./gamepad/Connect.js";
import {GameSocket} from "./websockets/client.js";
import {UIPage} from "./UIPage.js"
import {LobbyScreen} from "./lobby/LobbyScreen.js"
import {LobbyRoom} from "./lobby/LobbyRoom.js"

// import {useRoutes} from 'hookrouter';


// const GQL_ENDPOINT = process.env.REACT_APP_GQL_ENDPOINT || "http://localhost:8081/query";

// const GQL_TOKEN = process.env.REACT_APP_GQL_TOKEN || "Basic dXNlcjpwYXNzd29yZA==";

// export const header = { Authorization: `${GQL_TOKEN}` };




function App() {

    // print process.argv
    console.log(process.argv);
    
    return (
	<div className="App">
	    <header className="App-header">
		<Navigation />
	    </header>
	</div>
    );
}

var gameSocket;

class Navigation extends React.Component {

    constructor(props) {
	super(props);
	this.state = {
	    page: UIPage.PLAYER_SEL,
	};
	this.setPage = this.setPage.bind(this);
    }

    initSocket(key) {
	gameSocket = new GameSocket(key);
    }

    setPage (page) {
	console.assert(page in Object.values(UIPage), "Switch to invalid page", page)
	this.setState({
	    page: page,
	});
    }

    render() {
	var screen;

	switch(this.state.page){
	case UIPage.PLAYER_SEL:
	    screen = <Connect setPage={this.setPage} initSocket={this.initSocket} />
	    break;
	case UIPage.GAMEPAD:
	    screen = <Gamepad gameSocket={gameSocket} />
	    break;
	case UIPage.LOBBY_SEL:
	    screen = <LobbyScreen setPage={this.setPage} gameSocket={gameSocket} />
	    break;
	case UIPage.LOBBY:
	    screen = <LobbyRoom setPage={this.setPage} gameSocket={gameSocket}/>
	    break;
	default:
	    console.error("Unknown page " + this.state.page)
	}
	
	return (
	    screen
	);
    }
}

	    export default App;
