import React from "react";
import {UIPage} from "../UIPage.js"


export class LobbyRoom extends React.Component{

    constructor(props){
	super(props)

	this.socketListener = this.socketListener.bind(this)

	this.props.gameSocket.setMessageListener(this.socketListener);
	
	this.props.gameSocket.requestGameInfo()
	this.state = {game:{gameType:"", lobbyID:"N/A", clientNo: 0}}
    }

    socketListener(event) {
	let msg = JSON.parse(event.data)

	switch(msg.messageType){
	case "gameInfoRes":
	    this.setState({game:msg})
	    break;
	case "gameStart":
	    this.props.setPage(UIPage.GAMEPAD)
	    break;
	default:
	    console.error("Unknown message type: " + msg.messageType)
	}
    }

    render() {
	return <div className="lobby-info">
		   <div> {"Room #" + this.state.game.lobbyID}</div>
		   <div> {"Playing: " + this.state.game.gameType}</div>
		   <div> {this.state.game.clientNo < 2 ? "Not enough players" : "Waiting for turn"} </div>
	       </div>
    }
}
