import React from "react";
import "./LobbyList.css"

export class LobbyList extends React.Component {    
    
    render(){

	const listItems = this.props.games.map((game) => 
	    <LobbyItem gameSocket={this.props.gameSocket} game={game} key={game.lobbyID}/>
	)
					      
	return <ul className="games-list">
		   {listItems}
	       </ul>;
    }
}

class LobbyItem extends React.Component {

    constructor(props){
	super(props)
	this.onJoinClick = this.onJoinClick.bind(this);
    }

    onJoinClick(){
	const msg = JSON.stringify({messageType:"joinGame", lobbyID:this.props.game.lobbyID})
	this.props.gameSocket.sendMessage(msg)
    }    
    
    render(){
	const game = this.props.game
	return <li className="game-item">
		   <div className="title">
		       {"Room #" + game.lobbyID + " " }
		   </div>
		   <div className="client-no">
		       {game.clientNo + "/2"}
		   </div>
		   <div className="v-button" onMouseUp={()=>this.onJoinClick()} onTouchStart={()=>this.onJoinClick()}>
		       {"Join"}
		   </div>
	       </li>;
    }
}
