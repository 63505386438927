import React from "react";
import {LobbyList} from "./LobbyList.js";
import "./LobbyScreen.css"
import {UIPage} from "../UIPage.js"

export class LobbyScreen extends React.Component {

    constructor(props){
	super(props)

	this.onQuickJoinClick = this.onQuickJoinClick.bind(this)
	this.onCreateClick = this.onCreateClick.bind(this)
	this.onRefreshClick = this.onRefreshClick.bind(this)
	this.socketListener = this.socketListener.bind(this)


	this.props.gameSocket.setMessageListener(this.socketListener);

	this.state = {games:[]}

	this.props.gameSocket.requestGameList();
    }

    socketListener(event){
	let msg = JSON.parse(event.data)
	switch(msg.messageType){
	case "listGamesRes":
	    if(msg.games){
		this.setState({games:msg.games})
	    } else {
		this.setState({games:[]});
	    }
	    break;
	case "joinOk":
	    this.props.setPage(UIPage.LOBBY)
	    break;
	default:
	    console.error("Unknown message received: " + msg.messageType)
	    break;
	}

    }
    
    onQuickJoinClick(){
	const msg = JSON.stringify({messageType:"quickJoin", gameType:"Pong"})
	this.props.gameSocket.sendMessage(msg)
    }

    onCreateClick(){
	const msg = JSON.stringify({messageType:"createGame", gameType:"Pong", password: ""})
	this.props.gameSocket.sendMessage(msg)
    }

    onRefreshClick(){
	this.props.gameSocket.requestGameList();
    }
    
    render () {
	return <div>
		   <div className="button-panel">
		       <div className="v-button" onMouseUp={this.onRefreshClick} onTouchStart={this.onRefreshClick}>
			   {"Refresh"}
		       </div>
		       <div className="v-button" onMouseUp={this.onQuickJoinClick} onTouchStart={this.onQuickJoinClick}>
			   {"Quick Join"}
		       </div>
		       <div className="v-button" onMouseUp={this.onCreateClick} onTouchStart={this.onCreateClick}>
			   {"Create"}
		       </div>
		   </div>
		   <LobbyList games={this.state.games} gameSocket={this.props.gameSocket}/>
	       </div>
    }
}
