import React from "react";
import "./Connect.css";
import logo from "./../assets/logo_white.svg";

import {UIPage} from "./../UIPage.js"

class ConnectForm extends React.Component {
    constructor(props) {
	super(props);
	this.handleChange = this.handleChange.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
	this.prompt = "Enter code on screen"
	this.state = {
	    active: false,
	    value: this.prompt
	};
    }

    handleChange(event) {    this.setState({value: event.target.value});  }
    handleSubmit(event) {
	this.props.setPage(UIPage.LOBBY_SEL)
	this.props.initSocket(this.state.value);
	event.preventDefault();
    }

    formFocus(){
    	if (!this.state.active || this.state.value === this.prompt) {
			this.setState({active: true, value: ""});
		}
    }

    formBlur(){
    	if (this.state.value === "") {
    		this.setState({value: this.prompt})
		}
	}

    render() {
	return (
	    <form onSubmit={this.handleSubmit}>
		<input className={this.state.active ? "form-active" : "form-default"} type="text" value={this.state.value} onChange={this.handleChange} onFocus={() => this.formFocus()} onBlur={() => this.formBlur()} />
		<input className="v-button" type="submit" value="Submit" />
	    </form>
	);
    }
}

class Connect extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    width: window.innerWidth,
	    height: window.innerHeight,
	};
    }

    // Change component size on screen resize
    updateDimensions = () => {
	this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
	window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount() {
	window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
	return (
	    <div className="Main">
		{/* Main container */}
		<div className="LogoContainer">
		    <img src={logo} alt="Videowindow logo" className="logo" />
		    <p className="MiddleText">Videowindow Gamepad</p>
		</div>
		<div className="Sign-in">
		    <ConnectForm setPage={this.props.setPage} initSocket={this.props.initSocket} />
		</div>
	    </div>
	);
    }
}

export { Connect };
